(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/teams/assets/javascripts/services/fetch-team.js') >= 0) return;  svs.modules.push('/components/marketplace-data/teams/assets/javascripts/services/fetch-team.js');
"use strict";

const {
  roles,
  statuses
} = svs.components.marketplaceData.auth;
const {
  jupiter
} = svs.core;
const {
  groupTypeNames,
  typeByGroup
} = svs.components.marketplaceData.constants;
const {
  CREATED,
  UNPUBLISHED
} = svs.components.marketplaceData.teams.constants.groupStatus;
function mapGroup(_ref) {
  let {
    group
  } = _ref;
  const team = {
    id: group.groupId,
    name: group.name,
    type: typeByGroup[group.groupType],
    membersCount: group.numberOfMembers,
    token: group.token ? group.token.token : null,
    tokenExpires: group.token ? new Date(group.token.validUntil) : null,
    gamesAmount: group.numberOfContainersWithDraw,
    role: roles.getRole(group.currentUserRoleInGroup, statuses.getStatusById(group.currentUserStatusInGroup))
  };
  if (team.type === groupTypeNames.RETAILER) {
    team.address = group.address;
    team.description = group.description;
    team.phone = group.phone;
    team.email = group.email;
    team.city = group.city;
    team.website = group.website;
    team.isFollowing = group.currentUserStatusInGroup === 1;
    team.isPublished = !(group.groupStatus === CREATED || group.groupStatus === UNPUBLISHED);
    team.coverImage = {
      path: group.imageId
    };
    team.status = group.groupStatus;
    team.statusReason = group.groupStatusReason;
  }
  const members = [];
  if (team.type === groupTypeNames.PRIVATE) {
    group.members.forEach(member => {
      members.push({
        id: member.customerId,
        name: member.name,
        role: roles.getRole(member.role, statuses.getStatusById(member.status)),
        status: statuses.getStatusById(member.status),
        isMe: member.isCurrentUser,
        fractionSubscription: member.autobuyin ? parseInt(member.autobuyin.maxWeeklyAmount, 10) : null,
        fractionSubscriptionId: member.autobuyin ? member.autobuyin.autobuyinId : null
      });
    });
  }
  return {
    team,
    members
  };
}
const fetchTeam = (groupId, callback) => {
  jupiter.get("/marketplace/1/groups/".concat(groupId), response => {
    callback(undefined, mapGroup(response));
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.teams.services.fetchTeam', fetchTeam);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/teams/assets/javascripts/teams-constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/teams/assets/javascripts/teams-constants.js');

setGlobal('svs.components.marketplaceData.teams.constants', {
  MAX_MEMBERS_PER_TEAM: 200,
  groupType: {
    RETAIL: 3,
    PUBLIC: 2,
    PRIVATE: 1
  },
  groupStatus: {
    CREATED: 5,
    PUBLISHED: 6,
    UNPUBLISHED: 7
  },
  groupStatusReason: {
    UNDEFINED: 0,
    NOREASON: 1,
    INVESTIGATION: 2,
    GROUP_ADMIN: 3,
    SVSP_ADMIN: 4
  }
});


 })(window);
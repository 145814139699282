(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/teams/assets/javascripts/teams-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/teams/assets/javascripts/teams-actions.js');
"use strict";


const {
  CHAT_PURGE_ROOM
} = svs.components.chat.chatData.actions;
const {
  services
} = svs.components.marketplaceData.teams;
const {
  dialogWrapper,
  errorCodeHandler
} = svs.components.marketplaceData;
const END_TEAM_START = 'TEAMS/END_TEAM_START';
const END_TEAM_FAILED = 'TEAMS/END_TEAM_FAILED';
const END_TEAM_SUCCESS = 'TEAMS/END_TEAM_SUCCESS';
const endTeamAction = (groupId, callback) => dispatch => {
  dispatch({
    type: END_TEAM_START,
    groupId
  });
  services.endTeam(groupId, error => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: END_TEAM_FAILED,
          groupId,
          error
        });
        callback(new Error(err.message));
      }, {
        specificTeamMessage: true,
        defaultMessage: 'Något gick fel när laget skulle avslutas, försök igen senare.'
      });
    } else {
      dispatch({
        type: END_TEAM_SUCCESS,
        groupId
      });
      dispatch({
        type: CHAT_PURGE_ROOM,
        roomId: "marketplace_".concat(groupId)
      });
      callback();
    }
  });
};
const FETCH_TEAM_INIT = 'TEAMS/FETCH_TEAM_INIT';
const FETCH_TEAM_FAILED = 'TEAMS/FETCH_TEAM_FAILED';
const FETCH_TEAM_FINISHED = 'TEAMS/FETCH_TEAM_FINISHED';
const fetchTeamAction = function (groupId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
  return dispatch => {
    dispatch({
      type: FETCH_TEAM_INIT,
      groupId
    });
    services.fetchTeam(groupId, (error, data) => {
      if (error) {
        let errMsg = 'Något gick fel.';
        if (options.isRetailer && error.message.match(/unpublished/gi)) {
          errMsg = 'Ombudet kan inte hittas. Kontrollera att ombudet deltar i Spela tillsammans.';
        } else if (error.code === 404) {
          errMsg = options.isRetailer ? 'Ombudet kan inte hittas - kontrollera att länken är rätt.' : 'Laget kunde inte hittas.';
        }
        dispatch(dialogWrapper.actions.showDialog({
          title: errMsg,
          type: 'error'
        }));
        dispatch({
          type: FETCH_TEAM_FAILED,
          groupId,
          error
        });
        callback(error);
      } else {
        dispatch({
          type: FETCH_TEAM_FINISHED,
          team: data.team,
          members: data.members
        });
        callback(undefined);
      }
    });
  };
};
const FETCH_NEXT_RETAILERS_AND_TEAMS_INIT = 'TEAMS/FETCH_NEXT_RETAILERS_AND_TEAMS_INIT';
const FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED = 'TEAMS/FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED';
const FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS = 'TEAMS/FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS';
const fetchNextRetailersAndTeamsAction = queryParams => dispatch => {
  dispatch({
    type: FETCH_NEXT_RETAILERS_AND_TEAMS_INIT
  });
  services.fetchNextRetailersAndTeams((error, teams, count, offset, totalHits, groupTypeFilter) => {
    if (error) {
      dispatch({
        type: FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED,
        error
      });
    } else {
      dispatch({
        type: FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS,
        teams,
        count,
        offset,
        totalHits,
        groupTypeFilter
      });
    }
  }, queryParams);
};
setGlobal('svs.components.marketplaceData.teams.actions', {
  END_TEAM_START,
  END_TEAM_FAILED,
  END_TEAM_SUCCESS,
  endTeamAction,
  FETCH_TEAM_INIT,
  FETCH_TEAM_FAILED,
  FETCH_TEAM_FINISHED,
  fetchTeamAction,
  FETCH_NEXT_RETAILERS_AND_TEAMS_INIT,
  FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED,
  FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS,
  fetchNextRetailersAndTeamsAction
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/teams/assets/javascripts/services/fetch-next-retailers-and-teams.js') >= 0) return;  svs.modules.push('/components/marketplace-data/teams/assets/javascripts/services/fetch-next-retailers-and-teams.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  roles,
  statuses
} = svs.components.marketplaceData.auth;
const {
  jupiter
} = svs.core;
const {
  normalizeGames
} = svs.components.marketplaceData.normalize;
const {
  groupTypeNames,
  typeByGroup
} = svs.components.marketplaceData.constants;
const fetchNextRetailersAndTeams = (callback, queryParams) => {
  const {
    groupTypeFilter,
    offset
  } = queryParams;
  let url = '/marketplace/3/groups';
  if (groupTypeFilter) {
    url += "?groupTypeFilter=".concat(groupTypeFilter);
  }
  if (offset) {
    url += "&offset=".concat(offset);
  }
  jupiter.get(url, response => {
    let teams = response.groups;
    const {
      customerId
    } = response;
    if (response.groups === null) {
      teams = [];
    }
    const mappedTeams = teams.map(team => {
      var _team$currentUserAuto, _team$currentUserAuto2;
      const mappedTeam = _objectSpread({
        id: team.groupId,
        currentUser: {
          id: customerId,
          isMe: true,
          role: roles.getRole(team.currentUserRoleInGroup, statuses.getStatusById(team.currentUserStatusInGroup)),
          fractionSubscription: (_team$currentUserAuto = team.currentUserAutobuyin) !== null && _team$currentUserAuto !== void 0 && _team$currentUserAuto.maxWeeklyAmount ? Number((_team$currentUserAuto2 = team.currentUserAutobuyin) === null || _team$currentUserAuto2 === void 0 ? void 0 : _team$currentUserAuto2.maxWeeklyAmount) : null,
          status: statuses.getStatusById(team.currentUserStatusInGroup)
        },
        name: team.name,
        type: typeByGroup[team.groupType],
        membersCount: team.numberOfMembers,
        role: roles.getRole(team.currentUserRoleInGroup, statuses.getStatusById(team.currentUserStatusInGroup)),
        isFollowing: true,
        token: team.token ? team.token.token : null,
        tokenExpires: team.token ? team.token.validUntil : null,
        games: normalizeGames(customerId, ...team.containersWithDraw),
        gamesAmount: team.numberOfContainersWithDraw
      }, team.city ? {
        city: team.city
      } : {});
      if (mappedTeam.type === groupTypeNames.RETAILER) {
        mappedTeam.coverImage = {
          path: team.imageId
        };
      }
      return mappedTeam;
    });
    callback(undefined, mappedTeams, response.count, mappedTeams.length, response.totalHits, groupTypeFilter);
  }, error => callback(error.responseJSON.error));
};
setGlobal('svs.components.marketplaceData.teams.services.fetchNextRetailersAndTeams', fetchNextRetailersAndTeams);

 })(window);